<template>
  <div>
    <div class="header_wrapper">
      <p>{{ model.companyName }}</p>
      <div class="right">
        <!--    socket回调    -->
        <admin-socket></admin-socket>
        <!--  项目组切换  -->
        <!-- 首页  -->
        <Home/>
        <!-- 系统管理 -->
        <System/>
        <!-- 缴费提醒 -->
        <notice/>
        <!--  天气  -->
        <adminWeather/>
        <!--  头像  -->
        <adminAvatar/>
      </div>
    </div>
  </div>
</template>

<script>
import AdminAvatar from "./avatar/adminAvatar";
import notice from "./notice/index"
import adminWeather from "./weather/adminWeather";
import Home from "./home/index"
import System from "./system/index"
import {mapGetters} from "vuex";
export default {
  components: {AdminAvatar, adminWeather,Home,System ,notice},
  data(){
    return{
    }
  },
  computed:{
    model: function () {
      if(this.userInfo){
        return this.userInfo
      } else {
        return {
          companyName: '暂无',
        }
      }
    },
    ...mapGetters(['userInfo'])
  }

}
</script>

<style lang="scss" scoped>
.header_wrapper {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);

  >p {
    font-size: 16px;
    margin-left: 50px;
  }

  > .right {
    display: flex;
    margin-right: 50px;
  }

}
</style>
